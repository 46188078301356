import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import Menu from "@material-ui/icons/Menu"
import I18nContext from "../common/i18nContext"
import { getContent } from "../common/content"

const style = require("./header.module.scss")
if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")("a[href*=\"#\"]")
}
const logo = require("../images/logo.png")

const menuItems = getContent().menu

const quickContact = getContent().quickContact

const Header = ({ siteTitle }) => {
  const [mobileMenuVisible, setMobileMenuVisiblity] = React.useState(false)
  const handleMobileMenuLinkClick = (event) => {
    hideMobileMenu()
  }
  const hideMobileMenu = () => {
    setMobileMenuVisiblity(false)
  }

  const showMobileMenu = () => {
    setMobileMenuVisiblity(true)
  }

  return (
    <I18nContext.Consumer>
      {({ setLanguage, lang }) => (
        <header className={style.Header} id={"top"}>
          <div
            className={cx("container-fluid")}
          >
            <div className={cx("row justify-content-center align-content-center", style.Content)}>
              <div className={"col-auto"}>
                <h1 className={style.Logo}>{siteTitle}</h1>
              </div>
              <div className={"col d-none d-md-flex"}>
                <ul className={style.Menu}>
                  {menuItems[lang].map(item => (
                    <li><Link to={item.url}>{item.name}</Link></li>
                  ))}
                </ul>
              </div>
              <div className={cx("col-auto d-none d-md-flex")}>
                <div className={style.Contact}>
                  <div className={style.ContactData}>
                    <span>Email: <a
                      href="mailto:info@halk-logy.pl">info@halk-logy.pl</a></span>
                    <span>Tel: <a
                      href={"tel:+48 538 546 506"}>+48 538 546 506</a></span></div>
                </div>
                <ul className={style.LangSwitcher}>
                  <li onClick={() => setLanguage("pl")}>[PL]</li>
                  <li onClick={() => setLanguage("de")}>[DE]</li>
                </ul>
              </div>
              <div
                className={cx("col justify-content-end align-items-center d-inline-flex d-md-none", style.MobileMenuBtnWrapper)}>
                <Menu className={style.MobileMenuBtn} onClick={showMobileMenu}/>
              </div>

              {mobileMenuVisible &&
              <div className={cx("d-block d-md-none", style.MobileMenuOverlay)} onClick={hideMobileMenu}/>}
              {mobileMenuVisible && <div className={cx("d-block d-md-none", style.MobileMenu)}>
                <h1 className={style.MobileLogo}>{siteTitle}</h1>
                <ul>
                  {menuItems[lang].map(item => (
                    <li><Link onClick={handleMobileMenuLinkClick} to={item.url}>{item.name}</Link></li>
                  ))}
                </ul>
                <ul className={cx(style.LangSwitcher, "mt-4")}>
                  <li onClick={() => setLanguage("pl")}>[PL]</li>
                  <li onClick={() => setLanguage("de")}>[DE]</li>
                </ul>
              </div>}

            </div>
          </div>
        </header>
      )}
    </I18nContext.Consumer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
