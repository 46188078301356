import React from "react"

const style = require("./footer.module.scss")


const PageFooter = () => (
  <footer className={style.Footer}>
    © {new Date().getFullYear()}, HALK LOGY
  </footer>)

export default PageFooter
