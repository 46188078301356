import React from "react"

export const getContent = () => ({
  quickContact: {
    pl: "Szybki kontakt:",
    de: "Schneller Kontakt:",
  },
  banner: {
    pl: <>MOCNA STRONA TWOJEGO <br/>MAGAZYNU!</>,
    de: <>Starke Seite Ihres Lagers!</>,
  },
  menu: {
    pl: [
      {
        name: "Start",
        url: "/#top",
      },
      {
        name: "O Firmie",
        url: "/#o-firmie",
      },
      {
        name: "Usługi",
        url: "/#uslugi",
      },
      {
        name: "Kontakt",
        url: "/#kontakt",
      },
    ],
    de: [
      {
        name: "Start",
        url: "/#top",
      },
      {
        name: "Über uns",
        url: "/#o-firmie",
      },
      {
        name: "Dienstleistungen",
        url: "/#uslugi",
      },
      {
        name: "Kontakt",
        url: "/#kontakt",
      },
    ],
  },
  about: {
    pl: {
      title: <>Kilka słów o <strong>HALK LOGY</strong></>,
      content: <><p>Zajmujemy się produkcją i sprzedażą palet dłużycowych, stojaków do transportu szkła i okien, różnego
        rodzaju pojemników metalowych, pojemników skrzyniowych, koszy siatkowych i innych rozwiązań
        transportowo - magazynowych. Skupiamy się głównie na indywidualnych zamówieniach dostosowanych do potrzeb klienta.
        Dostarczamy zarówno niewielkie serie 40-50 stojaków jak i powtarzalne zamówienia przekraczające 1000 sztuk.
        Produkty w zależności od potrzeb klienta są lakierowane w dowolnym odcieniu RAL lub zabezpieczone metodą
        cynkowania ogniowego.</p>
        <p>Większość zamówień realizowana jest dla kontrahentów z rynku zachodnioeuropejskiego, jednak coraz częściej
          dostarczamy rozwiązania również dla polskich klientów.</p>
        <p>
          Dostawy atestowanej stali dla naszej produkcji powierzyliśmy największej polskiej hucie.
        </p>
        <p><strong>
          Czekamy na Państwa zapytania! Odwdzięczymy się atrakcyjną ofertą!
        </strong></p>
      </>,
    },
    de: {
      title: <>Über <strong>uns</strong></>,
      content: <><p>Wir beschäftigen uns mit der Produktion und dem Vertrieb von Langgutgestellen, Glas- und
        Fenstertransportgestellen, verschiedenen Stahlbehältern, Gitterboxen und anderen Lager-
        und Transportlösungen. Wir konzentrieren uns hauptsächlich auf individuelle Aufträge, die
        an Kundenbedarf angepasst sind. Wir liefern sowohl kleine Serien von 40-50 Gestellen als
        auch umfangreiche Produktgruppen über 1000 Stück.
        Unsere Produkte werden entsprechend Ihren Anforderungen entweder im beliebigen RAL-
        Ton lackiert oder feuerverzinkt.</p>
        <p>Die meisten Aufträge wurden bisher für die westeuropäischen Kunden gefertigt, immer
          öfter liefern wir auch Lösungen für die polnischen Betriebe.</p>
        <p>Mit der Stahllieferung für unsere Produktion wurde der größte polnische Stahlkonzern
          beauftragt.</p>
        <p><strong>Wir warten auf Ihre Anfrage und unterbreiten schnellstmöglich unser bestes Angebot!</strong></p>
      </>,
    },
  },
  services: {
    pl: {
      title: <>Nasze <strong>usługi</strong></>,
      content: [
        {
          img: "palety.jpg",
          name: <>Palety dłużycowe</>,
          desc: <>Palety do transportu profili i materiałów długich. Prosimy przesłać specyfikację lub rysunki, a my
            zajmiemy się resztą.</>,
        },
        {
          img: "stojaki.jpg",
          name: <>Stojaki na okna / szkło</>,
          desc: <>Oferujemy stojaki typu L i typu A umożliwiające bezpieczny transport i magazynowanie. Spełnimy
            wszystkie oczekiwania dotyczące gabarytów czy kolorów. Zapraszamy do kontaktu.</>,
        },
        {
          img: "pojemnik.jpg",
          name: <>Pojemniki metalowe</>,
          desc: <>Zbiorniki stalowe z blachy giętej lub gładkiej, przeznaczone do ciężkich, drobnych towarów. Prosimy o
            przesyłanie zapytań drogą mailową lub kontakt telefoniczny.</>,
        },
        {
          img: "inne_rozwiazania.jpg",
          name: <>Inne rozwiązania magazynowe</>,
          desc: <>Konstrukcje o dowolnych wymiarach, dostosowane do indywidualnych potrzeb. Jesteśmy do dyspozycji w
            kwestii materiału i zabezpieczeń antykorozyjnych.</>,
        },
        {
          img: "transport.jpg",
          name: <>Transport</>,
          desc: <>Zapewniamy transport, który dzięki stałemu monitorowaniu pojazdów pozwala na szybkie i
            bezpieczne dostawy ładunku w Polsce i Europie.</>,
        },
        {
          img: "doradztwo.jpg",
          name: <>Doradztwo techniczne</>,
          desc: <>Nasz zespół doradców chętnie podzieli się z Państwem swoją wiedzą. Aby skontaktować się z naszym
            działem pomocy technicznej <a href='#'>kliknij tutaj.</a></>,
        },
      ],
    },
    de: {
      title: <>Unsere <strong>Dienstleistungen</strong></>,
      content: [
        {
          img: "palety.jpg",
          name: <>Langgutgestelle</>,
          desc: <>Diese Gestelle sind für die Lagerung und den Transport verschiedener Langgüter bestimmt.
            Schicken Sie uns Ihre Anfrage und wir machen den Rest!</>,
        },
        {
          img: "stojaki.jpg",
          name: <>Fenster- und Glastransportgestelle</>,
          desc: <>Wir bieten eine breite Palette von Gestellen in L- und A-Form für den sicheren Transport
            Ihrer Waren. Die Gestelle werden gemäß Ihren Wünschen bzgl. Abmessung, Form und
            Tragkraft hergestellt. Sprechen Sie uns an.</>,
        },
        {
          img: "pojemnik.jpg",
          name: <>Metallbehälter</>,
          desc: <>Die Stahlbehälter mit Sicken- oder Glatten- Blechwänden für die schweren Güter. Schicken
            Sie uns eine E-Mail oder rufen Sie uns an!</>,
        },
        {
          img: "inne_rozwiazania.jpg",
          name: <>Sonstige Lagerlösungen</>,
          desc: <>Leichte Konstruktionen mit individuellen Abmessungen, angepasst an Ihren Bedarf. Wir
            stehen zur Verfügung, was Materialauswahl und Antikorrosionsbeschichtung betrifft.</>,
        },
        {
          img: "transport.jpg",
          name: <>Transport</>,
          desc: <>Wir organisieren den Transport mit ständiger Streckenüberwachung, die schnelle und
            sichere Anlieferung in Polen und ganz Europa ermöglicht.</>,
        },
        {
          img: "doradztwo.jpg",
          name: <>Technische Beratung</>,
          desc: <>Unser Beraterteam steht Ihnen gerne zur Verfügung. Zwecks Kontaktaufnahme klicken Sie
            bitte <a href='#kontakt'>hier.</a></>,
        },
      ],
    },
  },
  contact: {
    pl: {
      title: <><strong>Skontaktuj</strong> się z nami</>,
      form: {
        name: "Imie i nazwisko",
        email: "Adres e-mail",
        message: "Treść wiadomości",
        send: "Wyślij wiadomość",
        successMessage: "Wiadomość została wysłana",
        errorMessage: "Wystąpił błąd, prosimy o kontakt mailowy.",
      },
    },
    de: {
      title: <><strong>Kontaktieren</strong> Sie uns</>,
      form: {
        name: "Vor- und Nachname",
        email: "E-Mail Adresse",
        message: "Nachricht",
        send: "Senden",
        successMessage: "Die Nachricht wurde gesendet",
        errorMessage: "Ein Fehler ist aufgetreten, bitte kontaktieren Sie uns per E-Mail.",
      },
    },
  },
})
