/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import InProgress from './inProgress';
import Header from "./header"
import "./layout.scss"
import PageFooter from "./pageFooter"
const isBrowser = typeof window !== "undefined"
const IS_IN_PROGRESS = true;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {IS_IN_PROGRESS && <InProgress />}
      {!IS_IN_PROGRESS && <><Header siteTitle={data.site.siteMetadata.title}/>
        <main>{children}</main>
        <PageFooter/>
      </>}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
